const addUrlToGraphEntry = (graphEntry, url) => {
  if (graphEntry?.mainEntityOfPage && typeof window !== 'undefined') {
    return {
      ...graphEntry,
      mainEntityOfPage: {
        ...graphEntry.mainEntityOfPage,
        '@id': `${window.location.origin}${url}`,
      },
    }
  }
  return graphEntry
}

const addUrlsToSchemas = (schemas, url) => (
  {
    ...schemas,
    '@graph': schemas['@graph'].map((graphEntry) => addUrlToGraphEntry(graphEntry, url)),
  }
)

export default addUrlsToSchemas
