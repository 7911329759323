import { ReactNode } from 'react'
import type { Infection, Result, TestOutcome } from '@sh24/admin-api-js'
import { ResultsHistoryCard as ResultsHistoryCardComponent } from '@sh24/ui-components'
import groupBy from '../../utils/group-by'
import { resultOutcomeText, derivedResultPillBackgroundColour } from '../ResultCards/result-helpers'
import useThemeContext from '../../utils/use-theme-context'

// TODO: use a translation for infection name and site
const INFECTIONS = {
  chlamydia: 'Chlamydia',
  gonorrhoea: 'Gonorrhoea',
  hepatitisB: 'Hepatitis B',
  hepatitisC: 'Hepatitis C',
  hiv: 'HIV',
  syphilis: 'Syphilis',
  kidneyFunction: 'Kidney Function',
}

const SITES = {
  oral: 'oral',
  rectal: 'anal',
  vaginal: 'genital',
  urethral: 'genital',
}

const outcomeColors = {
  negative: '#C9FAC8',
  positive: '#FFC7CC',
  reactive: '#FFF1BF',
  action: '#EBF2F2',
}

const getOutcomeColor = (outcome: string) => {
  if (outcome === 'negative' || outcome === 'positive' || outcome === 'reactive') {
    return outcomeColors[outcome]
  }
  return outcomeColors.action
}

type GroupedResult = {
  infection: Infection,
  outcome: TestOutcome
  sampleSites: string[],
}

const getOutcomeOrder = (outcome: TestOutcome | number) => {
  if (typeof outcome === 'number') {
    return 98
  }
  return {
    positive: 1,
    reactive: 2,
    missing: 3,
    equivocal: 4,
    haemolysed: 4,
    insufficient: 4,
    invalid_sample: 4,
    lab_error: 4,
    not_processed: 4,
    negative: 5,
    numerical: 98,
    unknown: 99,
  }[outcome]
}

const resultSort = (a: GroupedResult, b: GroupedResult) => {
  // Sort outcomes according to custom order
  if (getOutcomeOrder(a.outcome) < getOutcomeOrder(b.outcome)) return -1
  if (getOutcomeOrder(a.outcome) > getOutcomeOrder(b.outcome)) return 1

  // Sort infections alphabetically
  if (a.infection.localeCompare(b.infection) < 0) return -1
  if (a.infection.localeCompare(b.infection) > 0) return 1

  return 0
}

const latestResult = (results: Array<Result>) => results.sort(
  (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
)[0]

const ResultsHistoryCard = ({
  results,
  orderId,
  status,
  cta,
}: {
  results: Result[]
  orderId: string
  status: string
  cta: ReactNode
}) => {
  const theme = useThemeContext()

  const resultsByTestType = groupBy(results, (result) => result.testName)
  const latestResultsByTestType = new Map(Array.from(resultsByTestType).map(
    (entry) => ([entry[0], latestResult(entry[1])]),
  ))
  const latestResults = Array.from(latestResultsByTestType.values())

  // Group results by Infection and Outcome
  const groupedResults = latestResults.reduce((acc, result) => {
    const { infection, sampleSite } = result
    const { outcome } = result

    if (infection === 'kidneyFunction') {
      return acc
    }

    let sameInfectionAndOutcome = acc.find(
      (group) => group.infection === infection && group.outcome === outcome,
    )

    if (!sameInfectionAndOutcome) {
      sameInfectionAndOutcome = { infection, outcome, sampleSites: [] }
      acc.push(sameInfectionAndOutcome)
    }

    if (sampleSite && sampleSite !== 'blood') {
      sameInfectionAndOutcome.sampleSites.push(SITES[sampleSite])
    }

    return acc
  }, [] as GroupedResult[])

  const formattedResults = groupedResults.sort(resultSort).map((result) => ({
    pillBackgroundColour: getOutcomeColor(result.outcome),
    pillText: result.outcome as string,
    site: result.sampleSites.join(', '),
    infection: INFECTIONS[result.infection],
  }))

  const kidneyFunctionResult = latestResults.find(
    (result) => result.infection === 'kidneyFunction',
  )

  if (
    kidneyFunctionResult
    && kidneyFunctionResult.numericalResult
    && kidneyFunctionResult.linkedDerivedResults
    && kidneyFunctionResult.linkedDerivedResults.egfr
  ) {
    const egfrOutcomeText = resultOutcomeText(
      { test: 'egfr', outcome: kidneyFunctionResult.linkedDerivedResults.egfr },
    )
    const egfrPillColour = derivedResultPillBackgroundColour(
      'egfr', kidneyFunctionResult.linkedDerivedResults.egfr,
    ) || theme?.palette?.tertiary100
    const scOutcomeText = resultOutcomeText(
      { test: 'kidneyFunction', outcome: kidneyFunctionResult.numericalResult.toString() },
    )

    const formattedKidneyFunctionResults = [
      {
        pillBackgroundColour: getOutcomeColor(kidneyFunctionResult.outcome),
        pillText: scOutcomeText,
        site: '',
        infection: 'Serum Creatinine',
      },
      {
        pillBackgroundColour: egfrPillColour,
        pillText: egfrOutcomeText,
        site: '',
        infection: 'eGFR',
      },
    ]
    formattedResults.push(...formattedKidneyFunctionResults)
  }

  return (
    <ResultsHistoryCardComponent
      results={formattedResults}
      orderId={orderId}
      status={status}
      cta={cta}
    />
  )
}

export default ResultsHistoryCard
