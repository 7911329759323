export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/tertiary200',
    middle: [
      '/tertiary100',
      '/white',
      '/secondary200',
      '/secondary100',
      '/white',
    ],
    last: '/white',
  },
  backgroundOverrides: {
    helpSnippets: '/white',
    quote_grid: '/tertiary100',
  },
  decorations: {
    first: '/curve/quarter/left',
    middle: [
      '/curve/quarter/left',
      '/curve/quarter/leftInverse',
      '/curve/quarter/left',
      '/curve/quarter/left',
      'None',
    ],
  },
  modules: {},
}
