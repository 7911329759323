import { useRouter } from 'next/router'
import styled from 'styled-components'

import { ButtonLink, Accordion } from '@sh24/ui-components'
import { PrepLastResults } from '@sh24/admin-api-js'

import { formatDate } from '../../../utils/helpers'
import ResultsHistoryCard from '../../ResultsHistoryCard/results-history-card'
import useTranslations from '../../../utils/use-translations'
import InlineLink from '../../InlineLink/inline-link'

const AccordionContent = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
  `}
`
const ViewResultsButton = ({ text, url }: { text: string, url: string }) => (
  <ButtonLink variation="primary" fullWidth iconPosition="none">
    <InlineLink
      text={text}
      url={url}
    />
  </ButtonLink>
)

const nextTestsDueText = (date: Date) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  const nextTestsDue = translations['prepDashboard.nextTestsDue']

  return `${nextTestsDue} ${formatDate({ dateString: date, locale })}`
}

const LastResults = ({
  prepSubscriptionState,
  lastResults,
  title,
}: {
  prepSubscriptionState: string,
  lastResults: PrepLastResults | null,
  title: string
}) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  if (!lastResults) {
    return (
      <Accordion
        variant="alternate"
        title={title}
        content={(
          <AccordionContent>
            {translations['prepDashboard.noResults']}
          </AccordionContent>
        )}
        closeText={translations['prepDashboard.close']}
      />
    )
  }

  return (
    <Accordion
      variant="alternate"
      title={title}
      previewContent={prepSubscriptionState === 'active' && lastResults.nextDue && nextTestsDueText(lastResults.nextDue)}
      content={(
        <AccordionContent>
          <ResultsHistoryCard
            results={lastResults.results}
            orderId={`Order ID: ${lastResults.sh24Uid}`}
            status={`Results ready: ${formatDate({ dateString: lastResults.createdAt, locale })}`}
            cta=<ViewResultsButton
              text="View result details"
              url={`/account/results/${lastResults.sh24Uid}`}
            />
          />
        </AccordionContent>
      )}
      closeText={translations['prepDashboard.close']}
      alwaysShowPreviewContent
    />
  )
}

export default LastResults
