import { Callout } from '@sh24/ui-components'
import useTranslations from '../../../utils/use-translations'

const InvitationError = () => {
  const translations = useTranslations()
  const title = translations['consultationAppointmentBookingModule.invitationErrorTitle']
  const content = translations['consultationAppointmentBookingModule.invitationErrorContent']

  return (
    <div className="row row-centered">
      <Callout
        title={title}
        backgroundColour="/secondary200"
        iconBackgroundColour="/white"
        icon="alert"
        content={content}
      />
    </div>
  )
}

export default InvitationError
