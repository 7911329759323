import React, { ReactNode } from 'react'
import RichTextDocumentContext from '../../contexts/rich-text-document-context'
import ResultCard from './result-card'
import useTranslations from '../../utils/use-translations'
import useResultCards from '../../utils/use-result-cards'

const ResultCardWithContext = ({
  value,
  resultCardsLoaded,
  openByDefault = false,
  collapsible = true,
}: {
  value: Record<string, ReactNode>,
  resultCardsLoaded: boolean,
  openByDefault: boolean,
  collapsible?: boolean,
}) => {
  const resultCards = useResultCards()
  const infection = value.infection as string
  const resultCard = resultCards[infection]

  if (!resultCard) {
    if (!resultCardsLoaded) return null

    const translations = useTranslations()
    return <p>{translations['account.results.noResultCard']}: {infection}</p>
  }

  return (
    <RichTextDocumentContext.Provider value={value}>
      <ResultCard resultCard={resultCard} openByDefault={openByDefault} collapsible={collapsible} />
    </RichTextDocumentContext.Provider>
  )
}

export default ResultCardWithContext
