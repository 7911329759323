export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/primary300',
    middle: [
      '/primary100',
      '/white',
      '/tertiary200',
      '/tertiary100',
      '/white',
    ],
    last: '/primary300',
  },
  backgroundOverrides: {
    helpSnippets: '/white',
    quote_grid: '/primary200',
  },
  decorations: {
    first: '/curve/quarter/left',
    middle: [
      '/curve/quarter/left',
      '/curve/quarter/leftInverse',
      '/curve/semi',
      '/curve/quarter/left',
      'None',
    ],
  },
  modules: {},
}
