import {
  ChlamydiaTreatmentStatus,
  Infection,
  Result,
  ResultsData,
  SampleSite,
  TestOutcome,
} from '@sh24/admin-api-js'
import groupBy from './group-by'

export type ResultsCardContext = {
  sh24Uid: string,
  infection: Infection,
  sampleSite: SampleSite,
  site?: string,
  sampleType: string,
  testsPerformed?: 'eia' | 'rpr' | 'eia,rpr',
  rprOutcome?: TestOutcome,
  eiaOutcome?: TestOutcome,
  outcome: TestOutcome,
  numericalResult?: number,
  updatedAt: number,
  ctOfferState?: ChlamydiaTreatmentStatus
  ctOfferToken?: string
}

const SITES = {
  rectal: 'anal',
  oral: 'oral',
  urethral: 'urine',
  vaginal: 'vaginal',
  blood: '',
}

const SAMPLE_TYPE = {
  oral: 'swab',
  urethral: 'urine',
  vaginal: 'swab',
  rectal: 'swab',
  blood: 'blood',
}

type TestPerformed = 'eia' | 'rpr'

const latestResult = (results: Array<Result>) => results.sort(
  (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
)[0]

const getSyphilisContextItems = (result: Result) => {
  let testPerformed
  if (result.testName === 'syphilisTreponemal') {
    testPerformed = 'eia'
  }
  if (result.testName === 'syphilisRpr') {
    testPerformed = 'rpr'
  }

  const eiaOutcome = result.testName === 'syphilisTreponemal' ? result.outcome : undefined
  const rprOutcome = result.testName === 'syphilisRpr' ? result.outcome : undefined

  return {
    ...(testPerformed ? { testsPerformed: testPerformed as TestPerformed } : {}),
    ...(eiaOutcome ? { eiaOutcome } : {}),
    ...(rprOutcome ? { rprOutcome } : {}),
  }
}

const resultsToCardContexts = (results: ResultsData, orderId: string): Array<ResultsCardContext> => {
  const thisOrder = results.orders.find((order) => order.sh24Uid === orderId)
  if (!thisOrder) return []

  if (thisOrder.results.length === 0) {
    return []
  }

  const resultsByTestType = groupBy(thisOrder.results, (result) => result.testName)
  const latestResultsByTestType = new Map(Array.from(resultsByTestType).map(
    (entry) => ([entry[0], latestResult(entry[1])]),
  ))
  const latestResults = Array.from(latestResultsByTestType.values())

  return latestResults.map((result) => {
    const { infection, sampleSite } = result
    const site = SITES[sampleSite]
    const sampleType = SAMPLE_TYPE[sampleSite]

    return ({
      sh24Uid: thisOrder.sh24Uid,
      infection,
      sampleSite,
      sampleType,
      ...(site ? { site } : {}),
      ...(getSyphilisContextItems(result)),
      outcome: result.outcome,
      numericalResult: result.numericalResult,
      linkedDerivedResults: result.linkedDerivedResults,
      updatedAt: result.createdAt.getTime(),
      ...(thisOrder.followOn.chlamydiaTreatment ? {
        ctOfferState: thisOrder.followOn.chlamydiaTreatment.status,
        ctOfferToken: thisOrder.followOn.chlamydiaTreatment.offerToken,
      } : {}),
    })
  })
}

export default resultsToCardContexts
