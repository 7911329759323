import React, { useState, useEffect, ReactNode } from 'react'
import styled from 'styled-components'
import {
  BreakpointHelpers,
  TextField,
  Button,
  ErrorMessages,
} from '@sh24/ui-components'

import { useRouter } from 'next/router'

const { tabletUp, desktopUp } = BreakpointHelpers

const Container = styled.div`
  ${({ theme }) => `
    padding: ${theme?.spacing?.md};
    border-radius: ${theme?.spacing?.sm};
    background-color: ${theme?.palette?.primary200};

    ${tabletUp(`
      display: flex;
      align-items: center;
      gap: ${theme?.spacing?.md};
    `)}
  `}
`

const ContentWrapper = styled.div`
  ${({ theme }) => `
    > h3 {
      margin-bottom: 0.8rem;
    }

    margin-bottom: ${theme?.spacing?.md};
    ${tabletUp(`
      flex-basis: 60%;
      margin-bottom: 0;
    `)}
    ${desktopUp(`
      flex-basis: 50%;
    `)}
  `}
`

const FormWrapper = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    ${tabletUp(`
      flex-basis: 40%;
    `)}
    ${desktopUp(`
      flex-basis: 50%;
      display: flex;
      align-items: center;
      gap: ${theme?.spacing?.md};
      margin-bottom: 0;
    `)}
  `}
`

const FormAndErrorWrapper = styled.div`
display: block;
`

const InputsWrapper = styled.div`
  ${({ theme }) => `
      display: flex;
      gap: ${theme?.spacing?.sm};
  `}
`

const InputWrapper = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`

const AvailabilityCheckerForm = (
  {
    title,
    intro,
    onSubmit,
    ageLabel,
    postcodeLabel,
    buttonLabel,
    ageErrors,
    postcodeErrors,
  } : {
    title: string,
    intro: ReactNode,
    onSubmit: ((age: string, postcode: string) => void),
    ageLabel: string,
    postcodeLabel: string,
    buttonLabel: string,
    ageErrors: string[],
    postcodeErrors: string[]
  }) => {
  const router = useRouter()

  const [age, setAge] = useState('')
  const [postcode, setPostcode] = useState('')

  useEffect(() => {
    setAge(router?.query?.age as string || '')
    setPostcode(router?.query?.postcode as string || '')
  }, [router?.query])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    onSubmit(age, postcode)
  }

  return (
    <div className="row row-centered">
      <div className="col col-12 col-md-8">
        <form onSubmit={handleSubmit} noValidate>
          <Container>

            <ContentWrapper>
              <h3>{title}</h3>
              {intro}
            </ContentWrapper>

            <FormAndErrorWrapper>

              <FormWrapper>
                <InputsWrapper>
                  <InputWrapper>
                    <TextField
                      id="age"
                      type="number"
                      label={ageLabel}
                      min="1"
                      max="120"
                      hideErrors
                      value={age}
                      errors={ageErrors}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAge(e.target.value)}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <TextField
                      id="postcode"
                      label={postcodeLabel}
                      hideErrors
                      value={postcode}
                      errors={postcodeErrors}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostcode(e.target.value)}
                    />
                  </InputWrapper>
                </InputsWrapper>

                <ButtonWrapper>
                  <Button type="submit" text={buttonLabel} />
                </ButtonWrapper>

              </FormWrapper>

              {!!ageErrors.length && <ErrorMessages id="age" errors={ageErrors} />}
              {!!postcodeErrors.length && <ErrorMessages id="postcode" errors={postcodeErrors} />}
            </FormAndErrorWrapper>

          </Container>

        </form>
      </div>
    </div>
  )
}

export default AvailabilityCheckerForm
