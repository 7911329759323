import Head from 'next/head'
import {
  Page,
  PageNavigation,
  useColourData,
} from '@sh24/ui-components'
import { useEffect, useMemo, useState } from 'react'
import {
  CURRENT_JOURNEY_KEY,
  getSession,
  clearSession,
} from '../order-journeys/_v2/state/actions'
import { trackEcommercePurchase } from '../order-journeys/_v2/tracking'
import SEO from '../components/SEO/seo'
import { useAppContext } from '../contexts/app-context'
import PageContext from '../contexts/page-context'
import SectionContext from '../contexts/section-context'
import JSONSchema from '../components/JSONSchema/json-schema'
import buildPageNav from '../utils/build-page-nav'
import useTranslations from '../utils/use-translations'
import lookupTranslations from '../utils/lookup-translations'
import templateConfig from './config'
import { getBackgroundColour, getDecoration } from './config/helpers'
import Headers from './headers'
import {
  AnchorModule,
  ButtonCloudModule,
  ConsultationAppointmentBookingModule,
  ContentModule,
  DynamicContentModule,
  GalleryModule,
  GridModule,
  HelpSnippetsModule,
  HighlightsModule,
  OrderSummaryModule,
  SearchInputModule,
  SupportLinksModule,
  AvailabilityCheckerModule,
  ContraceptionComparisonToolModule,
  AvailabilityCheckerResultsModule,
} from '../components/Modules'

const moduleMap = {
  buttonCloud: ButtonCloudModule,
  consultationAppointmentBooking: ConsultationAppointmentBookingModule,
  content: ContentModule,
  dynamicContent: DynamicContentModule,
  gallery: GalleryModule,
  grid: GridModule,
  helpSnippets: HelpSnippetsModule,
  highlights: HighlightsModule,
  orderSummary: OrderSummaryModule,
  searchInput: SearchInputModule,
  supportLinks: SupportLinksModule,
  availabilityChecker: AvailabilityCheckerModule,
  contraceptionComparisonTool: ContraceptionComparisonToolModule,
  availabilityCheckerResults: AvailabilityCheckerResultsModule,
}

const GenericPageTemplate = ({ page, backPath }) => {
  const { appContext } = useAppContext()
  const translations = useTranslations()
  const pageNavTranslations = lookupTranslations({ translations, lookup: 'pageNavigation.' })
  const pageNav = buildPageNav(page, pageNavTranslations)
  const config = templateConfig[page.template] || templateConfig.template_1

  useEffect(() => {
    if (page.pageType === 'completion') {
      const sessionKey = window.sessionStorage.getItem(CURRENT_JOURNEY_KEY)
      const journeyState = getSession({ sessionKey })

      if (!journeyState && !appContext?.preview) window.location.assign('/')

      if (journeyState?.context?.sessionKey === sessionKey) {
        trackEcommercePurchase(journeyState.context)
        clearSession({ sessionKey })
      }
    }
  }, [])

  const { modules } = page
  let sectionIndex = 0

  const [renderModules, setRenderModules] = useState(modules && modules.reduce((acc, obj) => {
    acc[obj.id] = true
    return acc
  }, {}))

  const updateRenderModules = (id, shouldRender) => {
    setRenderModules((prev) => ({
      ...prev,
      [id]: shouldRender,
    }))
  }

  const pageContextProviderValue = useMemo(() => ({
    ...page,
    features: {
      moduleHeaders: true,
    },
    updateRenderModules,
  }),
  [page])

  const Header = Headers[page.headerStyle]

  return (
    <PageContext.Provider value={pageContextProviderValue}>
      {page.jsonSchema && <JSONSchema id={page.id} schema={page.jsonSchema} />}
      {page.seo && <SEO seo={page.seo} url={page.url} Head={Head} />}

      {Header && <Header page={page} config={config} backPath={backPath} />}

      {modules?.map((module, index) => {
        const Component = moduleMap[module.contentType] || null
        if (!Component) return null

        const last = index === modules.length - 1

        const backgroundColour = getBackgroundColour({
          colours: config.backgroundColours,
          overrides: config.backgroundOverrides,
          moduleType: module.type || module.contentType,
          index: sectionIndex,
          last,
        })

        const nextBackgroundColour = getBackgroundColour({
          colours: config.backgroundColours,
          overrides: config.backgroundOverrides,
          moduleType: module.type || module.contentType,
          index: sectionIndex + 1,
          last: (index + 1) === modules.length - 1,
        })

        const decoration = getDecoration({
          decorations: config.decorations,
          backgroundColour,
          nextBackgroundColour,
          index: sectionIndex,
          last,
        })

        const options = config.modules?.[module.contentType] || {}

        const prevModule = modules[index - 1]
        sectionIndex += 1

        const colourData = useColourData(backgroundColour)

        const sectionContextProviderValue = useMemo(() => ({
          backgroundColour,
          decoration,
          isDark: colourData.isDark,
        }), [backgroundColour, decoration])

        return (
          <SectionContext.Provider
            key={module.id}
            value={sectionContextProviderValue}
          >
            <Page.Section
              backgroundColour={backgroundColour}
              decoration={decoration}
              hidden={!renderModules[module.id]}
              isDark={colourData.isDark}
            >
              <Page.Content>
                {prevModule?.contentType === 'anchor' ? <AnchorModule {...prevModule} /> : null}
                <Component {...module} {...options} />
              </Page.Content>
            </Page.Section>
          </SectionContext.Provider>
        )
      })}

      {pageNav && (
        <PageNavigation
          prevPageLink={pageNav.prevPageLink}
          nextPageLink={pageNav.nextPageLink}
        />
      )}
    </PageContext.Provider>
  )
}

export default GenericPageTemplate
