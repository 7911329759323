import { Callout } from '@sh24/ui-components'
import useTranslations from '../../../utils/use-translations'

const NoSlotsAlert = () => {
  const translations = useTranslations()
  const content = translations['consultationAppointmentBookingModule.noSlotsAlertContent']

  return (
    <Callout
      backgroundColour="/secondary200"
      iconBackgroundColour="/white"
      icon="alert"
      content={content}
    />
  )
}

export default NoSlotsAlert
