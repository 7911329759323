export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/primary200',
    middle: [
      '/white',
    ],
    last: '/white',
  },
  backgroundOverrides: {
    quote_grid: '/primary200',
    spotlight_grid: '/primary200',
    gallery: '/primary200',
  },
  decorations: {
    first: '/curve/quarter/left',
    middle: [
      '/curve/quarter/left',
      'None',
      'None',
      'None',
      'None',
    ],
  },
  modules: {},
}
