import styled from 'styled-components'

import { ButtonLink } from '@sh24/ui-components'
import {
  Prescription, PrepLastResults, PrepLastKidneyFunctionResult, PrepConsultationType,
} from '@sh24/admin-api-js'
import useTranslations from '../../../utils/use-translations'
import InlineLink from '../../InlineLink/inline-link'
import ConsultationBookedCard from '../ConsultationBooked/consultation-booked-card'
import LastPrescription from './last-prescription'
import LastResults from './last-results'
import LastKidneyFunctionResult from './last-kidney-function-result'

const SummaryContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: 400px;

    h2 {
      margin-bottom: ${theme?.spacing?.md}
    }
  `}
`

const ButtonWrapper = styled.div`
  ${({ theme }) => `
    margin-top: ${theme?.spacing?.md};
  `}
`

const PrepDashboardSummary = ({
  prepSubscriptionId,
  prepSubscriptionState,
  lastPrescription = null,
  lastResults = null,
  lastKidneyFunctionResult = null,
  prepConsultation = null,
}: {
  prepSubscriptionId: number;
  prepSubscriptionState: string;
  lastPrescription?: Prescription | null;
  lastResults?: PrepLastResults | null;
  lastKidneyFunctionResult?: PrepLastKidneyFunctionResult | null;
  prepConsultation?: PrepConsultationType | null;
}) => {
  const translations = useTranslations()

  return (
    <SummaryContainer>
      <h2>{translations['prepDashboard.atAGlance']}</h2>
      {prepConsultation && prepConsultation.state === 'scheduled' && (
        <ConsultationBookedCard
          consultation={prepConsultation}
        />
      )}
      <LastPrescription lastPrescription={lastPrescription} />
      <LastResults prepSubscriptionState={prepSubscriptionState} lastResults={lastResults} title={translations['prepDashboard.yourLatestTestResults']} />
      <LastKidneyFunctionResult prepSubscriptionState={prepSubscriptionState} lastKidneyFunctionResult={lastKidneyFunctionResult} title={translations['prepDashboard.yourLatestEgfrResults']} />

      {prepSubscriptionState !== 'closed' && (
        <ButtonWrapper>
          <ButtonLink variation="secondary" fullWidth iconPosition="none">
            <InlineLink url={`/account/prep/close/${prepSubscriptionId}`} text={translations['prepDashboard.cancelPrep']} />
          </ButtonLink>
        </ButtonWrapper>
      )}
    </SummaryContainer>
  )
}

export default PrepDashboardSummary
