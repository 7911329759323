const SiteNames = {
  fettle: 'Fettle',
  sh24: 'SH:24',
  hep_c_ireland: 'Hepatitis C Home Test',
  hcrg: 'The Sexual Health Hub',
  sh24_ireland: 'SH:24',
  bnssg: 'CHANGE ME',
}

export default SiteNames
