import { useRouter } from 'next/router'
import styled from 'styled-components'

import { Accordion } from '@sh24/ui-components'
import { Prescription } from '@sh24/admin-api-js'

import { formatDate } from '../../../utils/helpers'
import PrescriptionCard from '../../PrescriptionCards/prescription-card'
import useTranslations from '../../../utils/use-translations'

const AccordionContent = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
  `}
`
const LastPrescription = ({ lastPrescription }: { lastPrescription: Prescription | null }) => {
  const translations = useTranslations()
  const { locale } = useRouter()

  if (!lastPrescription) {
    return (
      <Accordion
        variant="alternate"
        title={translations['prepDashboard.yourLatestPrescription']}
        content={(
          <AccordionContent>
            {translations['prepDashboard.noPrescription']}
          </AccordionContent>
        )}
        closeText={translations['prepDashboard.close']}
      />
    )
  }

  const previewContent = (
    `${translations['prepDashboard.prescribed']} ${formatDate({ dateString: lastPrescription.prescribedAt, locale })}`
  )

  const prescriptionFormText = () => (lastPrescription.totalUnits > 1 ? 's' : '')

  const prescriptionSupplyLength = lastPrescription.courseLengthInMonths ? `${lastPrescription.courseLengthInMonths} ${translations['prescriptionCard.lengthOfSupply']} - ` : ''
  const prescriptionMethodString = `${lastPrescription.totalUnits} ${lastPrescription.form}${prescriptionFormText()}`

  const prescriptionDetails = [{
    title: lastPrescription.drugName,
    description: lastPrescription.ingredients,
    prescriptionQuantities: `${prescriptionSupplyLength} ${prescriptionMethodString}`,
  }]

  return (
    <Accordion
      variant="alternate"
      title={translations['prepDashboard.yourLatestPrescription']}
      previewContent={previewContent}
      content={(
        <PrescriptionCard
          items={prescriptionDetails}
          howToTake={translations['prescriptionCard.howToTake']}
          tagText={translations[`prescriptionCard.productType.${lastPrescription.productType}`]}
          tagIcon="tablet-capsule"
          sh24Uid={lastPrescription.sh24Uid}
          instructions={lastPrescription.instructions}
          prescribedAt={lastPrescription.prescribedAt}
        />
      )}
      closeText={translations['prepDashboard.close']}
      alwaysShowPreviewContent
    />
  )
}

export default LastPrescription
