export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/white',
    middle: [
      '/tertiary100',
    ],
    last: '/white',
  },
  backgroundOverrides: {},
  decorations: {
    first: '/curve/quarter/left',
    middle: [
      '/curve/semi',
    ],
  },
  modules: {},
}
