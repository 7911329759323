import template1 from './hcrg/template-1'
import template2 from './hcrg/template-2'
import template3 from './hcrg/template-3'

export default {
  hcrg: {
    template_1: template1,
    template_2: template2,
    template_3: template3,
  },
}
